import * as objectPath from 'object-path'
import { trans, transChoice } from '@src/core/helpers/translate'

// eslint-disable-next-line complexity
export default function useTasks () {
  const faIcons = {
    missing: 'fal fa-question',
    newrender: 'fal fa-image',
    templateimage: 'fal fa-image',
    styletypeimage: 'fal fa-image',
    templatedesignerbg: 'fal fa-image'
  }

  const itemStyles = {
    0: {
      bgClass: 'bg-light',
      iconBgClass: 'light-primary',
      iconClass: 'primary',
      pill: {
        class: 'label-light-warning',
        label: trans('tasks.index.list.pills.pending')
      }
    },
    1: {
      bgClass: 'bg-light-success',
      iconBgClass: 'success',
      iconClass: 'light-success',
      pill: {
        class: 'label-success',
        label: trans('tasks.index.list.pills.completed')
      }
    },
    2: {
      bgClass: 'bg-light-warning',
      iconBgClass: 'warning',
      iconClass: 'light-warning',
      pill: {
        class: 'label-warning',
        label: trans('tasks.index.list.pills.skipped')
      }
    },
    3: {
      bgClass: 'bg-light-danger',
      iconBgClass: 'danger',
      iconClass: 'light-danger',
      pill: {
        class: 'label-danger',
        label: trans('tasks.index.list.pills.deleted')
      }
    },
    4: {
      bgClass: 'bg-light',
      iconBgClass: 'primary',
      iconClass: 'primary',
      pill: {
        class: 'label-dark',
        label: trans('tasks.index.list.pills.unknown')
      }
    }
  }

  /**
   * getLogDetails()
   * - Returns a valid description based on provided data and translation-files
   * - Returns buttons with links and text if required
   */
  
  // eslint-disable-next-line complexity
  const getTaskDetails = (data) => {
    // Custom descriptions varies from type to type
    let desc = ''
    let btn = false

    if (objectPath.get(data, 'type', 'missing') === 'newrender') {
      // newrender -> A product render is required
      desc = trans('tasks.index.list.newrender.desc', {
        productname: objectPath.get(data, 'data.product.name', `(${transChoice('global.unknown', 0)})`),
        templatename: objectPath.get(data, 'data.template.name', `(${trans('tasks.index.list.newrender.unknownTemplate')})`) // eslint-disable-line max-len
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/designer`,
          label: trans('tasks.index.list.newrender.btn')
        }
      }
    } else if (objectPath.get(data, 'type', 'missing') === 'templateimage') {
      // templateimage -> A new template requires a preview-image
      desc = trans('tasks.index.list.templateimage.desc', {
        templatename: objectPath.get(data, 'data.template.name', `(${transChoice('global.unknown', 0)})`)
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }`,
          label: trans('tasks.index.list.templateimage.btn')
        }
      }
    } else if (objectPath.get(data, 'type', 'missing') === 'styletypeimage') {
      // styletypeimage -> A new styletype requires a preview image
      desc = trans('tasks.index.list.styletypeimage.desc', {
        styletypename: objectPath.get(data, 'data.styletype.name', `(${transChoice('global.unknown', 0)})`),
        templatename: objectPath.get(data, 'data.template.name', `(${transChoice('global.unknown', 0)})`)
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/styletypes`,
          label: trans('tasks.index.list.templateimage.btn')
        }
      }
    } else if (objectPath.get(data, 'type', 'missing') === 'templatedesignerbg') {
      // templatedesignerbg -> A new template requires a background image for the designer
      desc = trans('tasks.index.list.templatedesignerbg.desc', {
        templatename: objectPath.get(data, 'data.template.name', `(${transChoice('global.unknown', 0)})`)
      })

      const templateId = objectPath.get(data, 'data.template.id', false)
      if (templateId !== false) {
        btn = {
          to: `/templates/edit/${ templateId }/designer`,
          label: trans('tasks.index.list.templateimage.btn')
        }
      }
    } else {
      desc = trans(`tasks.index.list.${ objectPath.get(data, 'type', 'missing') }.desc`)
    }

    if (
      objectPath.get(data, 'status') === 1 || // Completed
      objectPath.get(data, 'status') === 2 || // Skipped
      objectPath.get(data, 'status') === 3 // Deleted
    ) {
      btn = false // status = 4 -> Unknown
    }

    return {
      desc,
      btn
    }
  }

  return {
    getTaskDetails,
    faIcons,
    itemStyles
  }
}
