import moment from 'moment'

export default function useDateTime () {
  /**
   * validTimestamp ()
   * Checks if the date-object is a valid date-object, or returned as seconds/nanoseconds from the Firebase DB
   *
   * @param object
   * @returns number
   */
  const validTimestamp = (object) => {
    if (object.seconds !== undefined) return parseInt(object.seconds, 10)
    else if (object._seconds !== undefined) return parseInt(object._seconds, 10)
    return parseInt(Date.parse(object) / 1000, 10)
  }

  const momentFullDate = (timeStamp) => moment.unix(validTimestamp(timeStamp)).format('LL')

  const momentHoursMinutesSeconds = (timeStamp) => moment.unix(validTimestamp(timeStamp)).format('LTS') // eslint-disable-line max-len

  return {
    momentFullDate,
    momentHoursMinutesSeconds
  }
}
